import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React from 'react'

import {
  Container,
  Jumbotron,
  Layout,
  Section,
  SEO,
  SmallTeaser,
  SmallTeaserView
} from '../components'
import { desktopBreakpoint } from '../styles'
import { AllMarkdown } from '../types'
import makePath from '../utils/makePath'

const style = {
  viewsWrapper: css`
    margin-bottom: 2em;
    margin-top: 2em;
    @media ${desktopBreakpoint} {
      display: flex;
      flex-direction: row;
      > * {
        margin-right: 2em;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  `
}

export const query = graphql`
query Index {
  news: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {fileAbsolutePath: {regex: "/(news)/.*\\.md$/"}, fields: { hidden: { eq: false } } }, limit: 2) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "D.M.YYYY")
          title
          summary
          image {
            mobile: childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 700, srcSetBreakpoints: []) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 600, maxHeight: 420, srcSetBreakpoints: []) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  events: allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {fileAbsolutePath: {regex: "/(events)/.*\\.md$/"}, fields: { hidden: { eq: false } } }, limit: 2) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "D.M.YYYY")
          title
          summary
          location
          image {
            mobile: childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 700, srcSetBreakpoints: []) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 600, maxHeight: 420, srcSetBreakpoints: []) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`

interface IndexPageProps {
  location: Location
  data: {
    news: AllMarkdown
    events: AllMarkdown
  }
}

export const IndexPage = ({ data: { news, events }, location: { pathname } }: IndexPageProps) => {
  const pageColor = 'white'
  return (
    <Layout currPath={pathname} pageColor={pageColor}>
      <SEO />
      <Section color="red" headerOffset={false} index={0} renderBg={true}>
        <Jumbotron teaser={true} />
      </Section>
      <Section color={'violet'} headerOffset={false} index={1} renderBg={true}>
        <Container>
          <div css={style.viewsWrapper}>
            <SmallTeaserView title="Aktuelles" path={makePath('aktuelles')}>
              {news.edges.map(
                (
                  {
                    node: {
                      frontmatter: { image, title },
                      fields: { slug }
                    }
                  },
                  i
                ) => (
                  <SmallTeaser
                    colorIndex={i % 2}
                    key={i}
                    image={image!}
                    path={slug!}
                    title={title!}
                  />
                )
              )}
            </SmallTeaserView>
            <SmallTeaserView title="Termine" path={makePath('termine')}>
              {events.edges.map(
                (
                  {
                    node: {
                      frontmatter: { image, title, date, location },
                      fields: { slug }
                    }
                  },
                  i
                ) => (
                  <SmallTeaser
                    key={i}
                    colorIndex={i % 2}
                    image={image!}
                    path={slug!}
                    title={title!}
                    suffix={[location]}
                    prefix={[date]}
                  />
                )
              )}
            </SmallTeaserView>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
